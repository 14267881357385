import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import CopyCodeSection from 'components/help/installCodeInstruction';
import Link from 'components/ui/link';

import img3 from 'img/help/bigcommerce/bigcommerce_1.png';
import img4 from 'img/help/bigcommerce/bigcommerce_2.png';
import img5 from 'img/help/bigcommerce/bigcommerce_3.png';
import img6 from 'img/help/bigcommerce/bigcommerce_4.png';
import img7 from 'img/help/bigcommerce/bigcommerce_5.png';
import logo from 'img/help/integrations/bigcommerce.png';

const Content = () => {
  return (
    <div>
      Using LiveSession requires placing a simple JavaScript code into your website. Read this
      article to learn how to install our LiveSession tracking code on BigCommerce website.
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        You need to force your visitors to use HTTPS to make our tracking code work. If you
        don&quot;t have an SSL, our tracking code won&quot;t work correctly.
      </div>
      <CopyCodeSection />
      <ul className="list-unstyled">
        <li>
          Open your BigCommerce dashboard and <strong>go to Storefront section</strong>.
          <Image src={img3} lazy alt="BigCommmerce integration" title="Go to Storefront section" />
        </li>
        <li>
          Then open <strong>Script manager</strong>.
          <Image src={img4} lazy alt="BigCommmerce integration" title="Open Script manager" />
        </li>
        <li>
          Click <strong>Create a Script</strong>
          <Image src={img5} lazy alt="BigCommmerce integration" title="Create a Script" />
        </li>
        <li>
          Type in name of script &quot;LiveSession&quot;. Set &quot;Location on page&quot; to
          &quot;Head&quot;. Add script to all pages and finally choose script type
          &quot;Script&quot;.
          <Image
            src={img6}
            lazy
            alt="BigCommmerce integration"
            title="Set up your script and add it"
          />
        </li>
        <li>
          Then please paste copied code into script input and save.
          <Image
            src={img7}
            lazy
            alt="BigCommmerce integration"
            title="Paste code into script input and save"
          />
        </li>
        <li>
          After that you&apos;re ready to go! Check if your script works properly using{' '}
          <Link href="/help/how-to-check-if-my-tracking-script-works/"> this guide</Link>
        </li>
      </ul>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script/',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
  {
    link: '/help/how-to-check-if-my-tracking-script-works/',
    title: 'How to check if my tracking script works',
    description: 'Check if your tracking code is installed correctly',
  },
];

const Wrapped = Article(Content);

export const frontmatter = {
  metaTitle: 'How to install LiveSession on BigCommerce?',
  metaDescription:
    'Read this article to learn how to install our LiveSession tracking code on BigCommerce shop',
  canonical: '/help/how-install-on-bigcommerce/',
  logo,
};

export default () => (
  <Wrapped related={related} section="Get Started" title={frontmatter.metaTitle} {...frontmatter} />
);
